@import './variables.scss';

.text-primary {
  color: $color-primary !important; // dark green from logo
}

.text-secondary {
  color: $color-secondary !important; // light green from logo
}

.text-contrast {
  color: $color-contrast !important; // white
}

.text-error {
  color: $color-error !important; // red complementary of green from logo
}

.text-warning {
  color: $color-warning !important; // yellow from logo
}

.text-info {
  color: $color-info !important; // blue complementary of yellow from logo
}

.text-success {
  color: $color-success !important; // light green from logo
}

.background {
  background-color: $color-primary !important; // green from logo background
}

.fs-6 {
  font-size: 0.75rem !important;
}
